import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { Layout, Sections, SeoComponent } from 'components';
import { LanguageContext } from 'context';

const PageTemplate = ({ data, pageContext }) => {
  const page = data.contentfulWebPage;
  const sections = page.sections;

  const menuItems = data.allContentfulWebSite.nodes
    .flatMap((node) => node.pages.flatMap((p) => [p, p.subPages]))
    .flat()
    .filter(Boolean)
    .filter((item) => item.menuRelated);
  const topMenuItems = data.allContentfulWebSite.nodes
    .flatMap((node) => node.pages.flatMap((p) => [p, p.subPages]))
    .flat()
    .filter(Boolean)
    .filter((item) => item.topMenuRelated);
  const headerItems = data.contentfulWebHeaderNavbar;
  const staticData = data.allContentfulDictionary.nodes;

  useEffect(() => {
    if (pageContext.nodeLocale === 'ar-SA') {
      document.documentElement.setAttribute('dir', 'rtl');
      document.documentElement.setAttribute('lang', 'ar');
      document.body.classList.add('rtl');
    } else {
      document.documentElement.removeAttribute('dir');
      document.documentElement.removeAttribute('lang');
      document.body.classList.remove('rtl');
    }
  }, [pageContext.nodeLocale]);

  console.log({ sections });

  return (
    <LanguageContext.Provider
      value={{
        currentLang: pageContext.nodeLocale,
        staticData,
      }}
    >
      <SeoComponent page={page} />
      <Layout pageContext={pageContext} menuItems={menuItems} headerItems={headerItems} topMenuItems={topMenuItems}>
        <Sections sections={sections} />
      </Layout>
    </LanguageContext.Provider>
  );
};

export default PageTemplate;

export const pageQueries = graphql`
  query ($slug: String, $nodeLocale: String, $seasonName: String) {
    allContentfulDictionary(filter: { node_locale: { eq: $nodeLocale } }) {
      nodes {
        contentItems {
          dictionary {
            title
          }
          keyString
          stringValue {
            stringValue
          }
        }
      }
    }
    allContentfulWebSite(
      filter: { webSlug: { eq: $seasonName }, node_locale: { eq: $nodeLocale } }
    ) {
      nodes {
        title
        webSlug
        showAlert
        pages {
          node_locale
          slug
          title
          id
          menuRelated
          topMenuRelated
          gtAction
          gtEvent
          gtEventCategory
          gtLabel
          subPages {
            ... on ContentfulWebPage {
              id
              slug
              title
              gtAction
              gtEvent
              gtEventCategory
              gtLabel
            }
          }
        }
      }
    }
    contentfulWebHeaderNavbar(node_locale: { eq: $nodeLocale }) {
      startDateCompetition
      days
      hours
      minutes
      seconds
      raceDate
      language
      newsItem
      logoImage {
        description
        file {
          url
        }
      }
      coloredLogoImage {
        description
        file {
          url
        }
      }
    }
    contentfulWebFooterSection(node_locale: { eq: $nodeLocale }) {
      description {
        raw
      }
      mainTitle
    }
    contentfulWebPage(slug: { eq: $slug }, node_locale: { eq: $nodeLocale }) {
      slug
      title
      node_locale
      seoTitle
      seoDescription {
        seoDescription
      }
      sections {
        title
        cssWrapper
        sectionWrapper
        components {
          ... on ContentfulWebCustomHtml {
            title
            ytVideoId
            description {
              description
            }
            type
            html {
              html
            }
          }
          ... on ContentfulWebOfferings {
            id
            type
            title
            cards {
              id
              eventName
              eventDescription {
                eventDescription
              }
              eventMainImage {
								id
                file {
									fileName
                  url
                }
              }
            }
          }
          ... on ContentfulWebColumnsLayout {
            type
            columns {
              components {
                ... on ContentfulWebContentBlock {
                  mainTitle
                  contentImages {
                    file {
                      url
                    }
                    gatsbyImageData(layout: FULL_WIDTH, quality: 90)
                  }
                  content {
                    raw
                  }
                }
              }
            }
          }
          ... on ContentfulWebIframeBlock {
            id
            type
            title
            srcUrl
          }
          ... on ContentfulWebImagesGrid {
            type
            images {
              file {
                url
              }
            }
          }
          ... on ContentfulSaudiExperience {
            type
            image {
              file {
                url
              }
            }
          }
          ... on ContentfulWebTopBanner {
            id
            type
            mainTitle
            shortTitle
            eventDate
            backgroundImage {
              gatsbyImageData(placeholder: BLURRED, quality: 90, layout: FULL_WIDTH)
              description
            }
            ytVideoId
            isVideoBackground
            logoImage {
              description
              file {
                url
              }
            }
            bannerText {
              bannerText
            }
            bannerButton {
              label
              link
            }
          }
          ... on ContentfulFerrariSchedule {
            title
            type
            arSchedule {
              images {
                image {
                  file {
                    url
                  }
                }
              }
            }
            enSchedule {
              images {
                image {
                  file {
                    url
                  }
                }
              }
            }
          }
          ... on ContentfulWebEventsGrid {
            id
            type
            title
            button {
              label
              link
              gtLabel
              gtEvent
              gtAction
              gtEventCategory
            }
            events {
              id
              eventName
              slug
              startDate
              staringTime
              endDate
              eventDescription {
                eventDescription
              }
              buyTicketsButton {
                label
                link
                gtLabel
                gtEvent
                gtAction
                gtEventCategory
              }
              eventMainImage {
                file {
                  url
                }
                gatsbyImageData(placeholder: BLURRED, quality: 90, layout: FULL_WIDTH)
                description
              }
            }
          }
          ... on ContentfulWebLatestNewsComponent {
            id
            title
            type
            articleCategory {
              slug
              article {
                id
                slug
                articleContent {
                  articleContent
                }
                title
                shortDescription
                articleImage {
                  gatsbyImageData(placeholder: BLURRED, quality: 90, layout: FULL_WIDTH)
                  description
                }
                articleDate(formatString: "DD MMMM, Y")
                featured
              }
            }
            buttonTab {
              label
              link
              gtLabel
              gtEvent
              gtAction
              gtEventCategory
            }
          }
          ... on ContentfulWebOngoingEvents {
            id
            type
            title
            event {
              id
              eventName
              slug
              eventDescription {
                eventDescription
              }
              eventMainImage {
                gatsbyImageData(placeholder: BLURRED, quality: 90, layout: FULL_WIDTH)
                description
              }
            }
          }
          ... on ContentfulWebOurVision {
            id
            type
            shortTitle
            mainTitle
            personName
            personPosition
            description {
              description
            }
            personImage {
              gatsbyImageData(placeholder: BLURRED, quality: 100, width: 426, height: 450)
              description
            }
          }
          ... on ContentfulWebTopVideoBanner {
            id
            type
            title
          }
          ... on ContentfulWebAboutUs {
            id
            title
            type
            shortTitle
            mainTitle
            content1 {
              raw
            }
            content2 {
              raw
            }
            image1 {
              description
              file {
                url
              }
            }
            image2 {
              file {
                url
              }
              description
            }
          }
          ... on ContentfulWebHero {
            id
            type
            title
            subtitle
            button {
              label
              link
            }
            description {
              raw
            }
            image {
              file {
                url
              }
            }
          }
          ... on ContentfulWebEventsCarousel {
            id
            title
            type
            mainTitle
            description {
              description
            }
            theme
            events {
              id
              eventName
              slug
              startDate
              endDate
              eventDescription {
                eventDescription
              }
              buyTicketsButton {
                label
                link
                gtLabel
                gtEvent
                gtAction
                gtEventCategory
              }
              eventMainImage {
                gatsbyImageData(placeholder: BLURRED, quality: 90, layout: FULL_WIDTH)
                description
              }
            }
            button {
              label
              link
              gtLabel
              gtEvent
              gtEventCategory
              gtAction
            }
          }
          ... on ContentfulWebSponsorCarousel {
            id
            title
            type
            carousalBackground {
              id
              title
              file {
                url
              }
            }
            identification
            sponsorsItems {
              id
              link
              logoImage {
                gatsbyImageData(placeholder: BLURRED, quality: 90, layout: FULL_WIDTH)
                description
              }
              description {
                description
              }
            }
          }
          ... on ContentfulWebTopVideoBanner {
            id
            type
            title
            videoTitle
            video {
              description
              file {
                url
              }
            }
          }
          ... on ContentfulWebVehicleCategories {
            id
            title
            type
            categories {
              id
              vehicleName
              link
              image {
                gatsbyImageData(placeholder: BLURRED, quality: 90, layout: FULL_WIDTH)
                description
              }
            }
          }
          ... on ContentfulWebGallery {
            id
            title
            type
            images {
              id
              caption
              image {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 85)
                description
              }
            }
            mainTitle
          }
          ... on ContentfulWebAboutEvent {
            id
            type
            mainTitle
            wrapper
            description {
              raw
              references {
                contentful_id
                title
                link
                type
                appStoreImage {
                  file {
                    url
                  }
                }
              }
            }
            patternTheme
            button {
              label
              link
              gtLabel
              gtEvent
              gtAction
              gtEventCategory
            }
            image {
              file {
                url
              }
              description
            }
            eventLogo {
              file {
                url
              }
              description
            }
          }

          ... on ContentfulWebDriversTabsCarousel {
            id
            type
            mainTitle
            description {
              description
            }
            button {
              label
              link
              gtLabel
              gtEvent
              gtAction
              gtEventCategory
            }
            vehiclesCategory {
              id
              vehicleName
              drivers {
                id
                driverName
                driverRank
                driverPoints
                country
                dateOfBirth
                entryNumber
                car
                highestGridPosition
                teamTitle
                driverProfilePicture {
                  gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 85)
                  description
                }
              }
            }
          }
          ... on ContentfulWebOpeningClosingCeremonies {
            id
            type
            mainTitle
            description {
              description
            }
            mainImage {
              gatsbyImageData(placeholder: BLURRED, quality: 90, layout: FULL_WIDTH)
              description
            }
          }
          ... on ContentfulWebTheRoute {
            id
            type
            mainTitle
            description {
              raw
            }
            mainImage {
              gatsbyImageData(placeholder: BLURRED, quality: 90, layout: FULL_WIDTH)
              description
            }
          }
          ... on ContentfulWebGetZone {
            id
            title
            type
            mainTitle
            description {
              raw
            }
            sliderImages {
              id
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 85)
            }
          }
          ... on ContentfulWebCornicheCircuit {
            id
            title
            type
            mainTitle
            description {
              raw
            }
            backgroundImage {
              id
              file {
                url
              }
            }
            button {
              label
              link
              gtLabel
              gtEvent
              gtAction
              gtEventCategory
            }
          }
          ... on ContentfulWebRaceFacts {
            id
            type
            title
            mainTitle
            factsItems {
              id
              mainTitle
              description
              description2
            }
            button {
              label
              link
              gtLabel
              gtEvent
              gtAction
              gtEventCategory
            }
          }
          ... on ContentfulWebTeamStandings {
            id
            title
            mainTitle
            type
            teams {
              id
              teamName
              teamPoints
              position
              carImage {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 85)
                description
              }
            }
            button {
              label
              link
              gtLabel
              gtEvent
              gtAction
              gtEventCategory
            }
          }
          ... on ContentfulWebDriverCarousel {
            id
            title
            mainTitle
            carouselDescription
            type
            drivers {
              drivers {
                id
                driverName
                driverRank
                driverPoints
                country
                dateOfBirth
                entryNumber
                car
                teamTitle
                worldChampionships
                highestRaceFinish
                highestGridPosition
                grandPrixEntered
                placeOfBirth
                podiums
                driverProfilePicture {
                  gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 85)
                  description
                }
                driverPhoto {
                  gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 85)
                  description
                }
              }
            }
            button {
              label
              link
              gtLabel
              gtEvent
              gtAction
              gtEventCategory
            }
          }
          ... on ContentfulWebContentBlock {
            type
            title
            subTitle
            mainTitle
            cssWrapper
            content {
              raw
            }
            contentImages {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
              description
            }
            resellerAddress {
              phone
              email1
              website
            }
            blockType
          }
          ... on ContentfulWebAllNewsComponent {
            id
            title
            type
            articles {
              slug
              article {
                id
                slug
                articleContent {
                  articleContent
                }
                title
                shortDescription
                articleImage {
                  gatsbyImageData(placeholder: BLURRED, quality: 90, layout: FULL_WIDTH)
                  description
                }
                articleDate(formatString: "DD MMMM, Y")
                featured
              }
            }
          }
          ... on ContentfulWebVideoComponent {
            id
            title
            type
            mainTitle
            mainVideo {
              file {
                url
              }
            }
          }
          ... on ContentfulWebAboutJeddahCornicheCircuit {
            id
            title
            type
            title1
            title2
            content1 {
              raw
            }
            content2 {
              raw
            }
            image1 {
              description
              file {
                url
              }
            }
            image2 {
              file {
                url
              }
              description
            }
          }
          ... on ContentfulWebVenueMap {
            id
            type
            title
            mainTitle
            downloadFileText
            image {
              gatsbyImageData(placeholder: BLURRED, quality: 90, layout: FULL_WIDTH)
              description
            }
            pdfFile {
              file {
                url
              }
            }
          }
          ... on ContentfulWebEventsCalendar {
            id
            title
            type
            mainTitle
            events {
              id
              eventName
              slug
              startDate
              endDate
              viewEvent
              comingSoon
              isFinished
              eventDescription {
                eventDescription
              }
            }
          }
          ... on ContentfulWebDesertXpCarousel {
            id
            title
            type
            items {
              id
              mainTitle
              content {
                raw
              }
              backgroundImage {
                gatsbyImageData(placeholder: BLURRED, quality: 90, layout: FULL_WIDTH)
                description
              }
            }
          }
          ... on ContentfulWebTeamCarousel {
            id
            title
            type
            subHeadTitle
            mainTitle
            teamCarousel {
              id
              teams {
                id
                teamName
                position
                aboutTheTeam {
                  aboutTheTeam
                }
                results
                teamDrivers {
                  id
                  driverName
                  driverProfilePicture {
                    gatsbyImageData(placeholder: BLURRED, quality: 90, layout: FULL_WIDTH)
                    description
                  }
                }
              }
            }
          }
          ... on ContentfulWebJccFitRegisterForm {
            type
            activities
          }
          ... on ContentfulMagazine {
            type
            title
            subTitle
            magazinePages {
              gatsbyImageData(placeholder: BLURRED, quality: 90, layout: FULL_WIDTH)
            }
            magazineFile {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`;

// ... on ContentfulWebDakarRoute {
//   id
//   title
//   type
//   image {
//     gatsbyImageData(placeholder: BLURRED, quality: 90, layout: FULL_WIDTH)
//     description
//   }
// }